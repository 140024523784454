import L from 'leaflet'

function getLayers(){
    return {
        'esri': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
            maxZoom: 16
        })
    }
}

const redIcon = L.icon({
    iconUrl: 'images/marker-icon-red.webp',
    iconAnchor: [12.5, 41],
})

const blueIcon = L.icon({
    iconUrl: 'images/marker-icon.webp',
    iconAnchor: [12.5, 41],
    shadowUrl: 'images/marker-shadow.webp',
})

const addressMarked = (address) => {
    return L.marker([address.latitude, address.longitude], {icon: redIcon})
}
const officeMarker = (position) => {
   return L.marker(position, {icon: blueIcon})
}

export {addressMarked, redIcon, blueIcon, officeMarker, getLayers}