import React from "react";
import {Link} from "react-router-dom"
import {useSelector} from "react-redux";
import Badge from "@material-ui/core/Badge";
import {selectFavorites} from "../features/job/jobSlice";
import {makeStyles, ThemeProvider} from "@material-ui/styles";
import {theme} from "../features/theme/theme";
import clsx from "clsx";

const styles = makeStyles({
    root: {
        backgroundColor: theme.palette.primary.dark,
        borderBottom: "1px solid rgba(255,255,255,.1)"
    }
});

export function NavBar(){
    const favorites = useSelector(selectFavorites);
    const classes = styles();

    function showFooter(){
        const menu = document.getElementById('navbarCollapse')
        menu.classList.remove('d-block')
    }

    function toggleMenu(){
        const menu = document.getElementById('navbarCollapse')
        menu.classList.toggle('d-block')
    }

    return (
        <ThemeProvider theme={theme}>
            <header>
                <nav className={clsx("navbar navbar-expand-md navbar-dark fixed-top", classes.root)}>
                    <Link className="navbar-brand" to="/" onClick={() => showFooter()}>Accueil</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" onClick={() => toggleMenu()}
                            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/geolocalisateur" onClick={() => showFooter()}>Géolocalisateur</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/glossaire" onClick={() => showFooter()}>Glossaire</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/mes-favoris" onClick={() => showFooter()}>
                                    <Badge badgeContent={favorites.length} color="primary">
                                        Mes favoris
                                    </Badge>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </ThemeProvider>
    )
}