import axios from 'axios';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSelector} from 'reselect';
import StorageService from "../../../services/storage";
import {Transport} from "../../../model/tranport";
import {getPlace} from "../../address/addressSlice";
const storage = new StorageService();
const SEARCH_HOST = process.env.REACT_APP_API_URL;
export const fetchTransports = createAsyncThunk('/transports', async () => {
    const url = `${SEARCH_HOST}/transport/`
    const response = await axios.get(url);
    return response.data;
})
export const transportSlice = createSlice({
    'name': 'transport',
    'initialState': {
        status: false,
        timeId: storage.getTimeId(),
        transports: [],
        favorite: null
    },
    reducers: {
        setFavoriteTransport: (state, action) => {
            storage.setFavoriteTransport(action.payload);
            state.favorite = action.payload;
        },
        setTimeId: (state, action) => {
            storage.setTimeId(action.payload);
            state.timeId = action.payload;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        hideLoading: (state) => {
            state.loading = false;
        }
    },
    extraReducers: {
        [fetchTransports.pending]: (state) => {
            state.status = true;
            state.transports = [];
        },
        [fetchTransports.fulfilled]: (state, action) => {
            state.status = false;
            state.transports = action.payload.map(t => new Transport(t)).sort((a, b) => a.id - b.id);
            if (state.favorite === null) {
                state.favorite = state.transports[0];
            }
        },
        [fetchTransports.rejected]: (state) => {
            state.status = false;
            state.transports = [];
        },
    }
});
export const selectTransports = state => state.transport.transports;
export const getFavorite = state => state.transport.favorite;
export const getTimeId = state => state.transport.timeId;
export const getTransports = state => state.transport.transports;
export const getAvailableTimes = createSelector(
    [getFavorite, getTimeId],
    (favorite, timeId) => {
        return favorite ? favorite.cutoffsec.slice(0, timeId + 1) : [];
    }
);
export const getAvailableTimesSize = createSelector(
    [getAvailableTimes],
    (times) => {
        return times.length;
    }
);
export const getTimes = createSelector(
    [getFavorite],
    (favorite) => {
        return favorite ? favorite.cutoffsec : [];
    }
);
export const getFavoriteTime = createSelector(
    [getFavorite, getTimeId],
    (favorite, timeId) => {
        if (favorite) {
            return favorite.cutoffsec[timeId];
        } else {
            return "";
        }
    }
);
export const getDirectionUrl = createSelector(
    [getPlace, getFavorite],
    (place, favorite) => {
        return function (origin) {
            let url = null;
            if (place && favorite) {
                const params = {
                    'origin': [origin.latitude, origin.longitude],
                    'destination': [place.latitude, place.longitude],
                    'travelmode': favorite.gmap
                };

                url = "https://www.google.com/maps/dir/?api=1";
                Object.keys(params).forEach(k => {
                    url += `&${k}=${encodeURIComponent(params[k])}`;
                });

            }
            return url;
        }
    }
);
export const {setFavoriteTransport, setTimeId, hideLoading} = transportSlice.actions;
export default transportSlice.reducer;

