import React from "react";
import {Jumbotron} from "./jumbotron";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {ThemeProvider} from "@material-ui/styles";
import {theme} from "../features/theme/theme";
import CssBaseline from "@material-ui/core/CssBaseline";


const styles = makeStyles({
    icon: {
        fontFamily: "Ubuntu",
        fontSize: "1.1rem",
        fontWeight: "700",
        color: theme.palette.primary.dark,
        minHeight: "60px",
        padding: "10px 0 0 50px",
        display: "block",
    },
    objective: {
        background: " url('./images/titre_objectifs.webp')",
        backgroundRepeat: "no-repeat"
    },
    methodology: {
        background: " url('./images/titre_methodologie.webp')",
        backgroundRepeat: "no-repeat"
    },
    usage: {
        background: " url('./images/titre_modeemploi.webp')",
        backgroundRepeat: "no-repeat"
    },
    credit: {
        background: " url('./images/titre_credits.webp')",
        backgroundRepeat: "no-repeat"
    },
    subtitle: {
        fontFamily: "Ubuntu",
        fontSize: "1rem",
        fontWeight: 400,
        color: theme.palette.primary.dark,
    },
    justify: {
          textAlign: 'justify'
    }
});

function HomeDescription(){
    const classes = styles()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={clsx("container")}>
                <div className={clsx("row", "root")}>
                    <div className={clsx("col-md-6", "col-12")}>
                        <h2 className={clsx(classes.icon, classes.objective)}>Les objectifs de l'application</h2>
                        <h3 className={classes.subtitle}>Objectif principal</h3>
                        <p className={classes.justify}>L’objectif premier du Géolocalisateur d’entreprises de Lanaudière est de fournir aux personnes à la recherche d’un emploi un outil permettant d’identifier visuellement,
                            sur une carte interactive, les entreprises présentes dans différentes zones de dessertes de 10 à
                            1h15 minutes d’une adresse, en fonction du mode de déplacement :
                            à pied, à vélo, en automobile ou en transport en commun. Les entreprises identifiées sont
                            susceptibles d’embaucher du personnel dans les professions sélectionnées.
                            Ainsi, le géolocalisateur fournit une liste des entreprises afin de soutenir la recherche
                            d’emploi de l’utilisateur.</p>
                        <h3 className={classes.subtitle}>Objectif secondaire</h3>
                        <p className={classes.justify}>L’objectif secondaire du géolocalisateur d’entreprises est de permettre à diverses organisations
                            ou entreprises de Lanaudière de localiser sur une carte géographique
                            interactive, les entreprises se situant dans différentes zones de dessertes de 10 à 1h15 minutes
                            d’une adresse, en fonction du mode de déplacement : à pied, à vélo,
                            en automobile ou en transport en commun, afin d’approfondir leur connaissance des entreprises
                            lanaudoises et de favoriser l’atteinte d’objectifs spécifiques
                            à leur mission.</p>
                        <h2 className={clsx(classes.icon, classes.methodology, "mt-xl-5")}>Méthodologie</h2>
                        <p className={classes.justify}>Une association entre chaque profession (comptant au moins 50 personnes occupées dans
                            Lanaudière) et les secteurs d’activité a été effectuée en utilisant la matrice
                            professions (en CNP 4) et les secteurs d'activité (SCIAN 4 chiffres) construite
                            par Statistique Canada pour le Québec dans le cadre du recensement de 2016.
                            Le critère d’association retenu est que la part des personnes occupées de Lanaudière
                            dans les secteurs d’activité (SCIAN 4 chiffres) représente plus de 2% du total des personnes occupées
                            dans chaque profession. </p>
                        <p className={classes.justify}>Par la suite, un pairage entre les secteurs d’activité et la base de données du
                            Répertoire des établissements de cinq employés et plus de Services Québec Lanaudière a été effectué en fonction
                            des codes SCIAN. </p>
                        <h2 className={classes.subtitle}>Méthode pour délimiter les isochrones selon le mode de transport</h2>
                        <p className={classes.justify}>Un fois une adresse ou un code de postal sélectionné, les isochrones suivantes sont calculées selon le mode de transport :
                            10, 15, 20, 30 et 45 minutes en automobile  ou vélo ; 15, 30, 45, 60 et 75 minutes en transport en commun ;
                            10, 15, 20, 25 et 30 minutes à pied. La délimitation des isochrones est réalisée avec
                            l’API OpenTripPlanner et basée sur les données géographiques des réseaux routiers et
                            cyclables d’OpenStreetMap.</p>
                        <p className={classes.justify}>
                            Pour les isochrones en transport en commun, nous avons aussi utilisé les fichiers GTFS des agences de transport du CRT Lanaudière et des MRC de L'Assomption et les Moulins
                            (ces données en accès libre comprennent les horaires, les arrêts et les lignes de transport en commun). Pour ce faire, trois critères ont été appliqués. Premièrement,
                            pour une localisation donnée (adresse ou code postal), si aucun arrêt en transport en commun n’est présent à moins de 750 mètres à travers le réseau de rues, aucune isochrone n’est calculée.
                            Deuxièmement, la distance de marche maximale pour un déplacement en transport en commun a été fixée à 1000 mètres. Troisièmement, plusieurs isochrones ont été calculées
                            pour le lundi 18 janvier 2021 à 8h, 8h10, 8h20, 8h30, 8h40 et 8h50; nous retenons ensuite l’isochrone la plus grande en termes de superficie.
                        </p>
                    </div>
                    <div className={clsx("col-md-6", "col-12")}>
                        <div className={"alert alert-warning"}>
                        <h2 className={clsx(classes.icon, classes.usage, "")}>Mode d'emploi</h2>
                        <p className={clsx(classes.justify, "alert alert-warning")}>À partir des menus déroulants dans l’espace de recherche dans la bande à gauche de l’application,
                            il suffit de choisir une profession ou un secteur (un maximum de 3 secteurs), saisir une adresse ou un
                            code postal (ex: J6X4P3),
                            choisir le mode de déplacement ainsi que le nombre de minutes de déplacement dans le coin
                            supérieur droit et le tour est joué !
                            Vous obtiendrez ainsi une liste d’entreprises afin d’optimiser vos recherches d’emploi !
                            Vous pouvez aussi choisir une ou des entreprises et cliquer sur leur site web. Vous pourrez
                            alors mieux connaître l’entreprise et possiblement postuler en ligne.</p>
                        </div>
                        <h2 className={clsx(classes.icon, classes.credit, 'mt-xl-5')}>Crédits de réalisation du site</h2>
                        <p className={classes.justify}>Le géolocalisateur d’entreprises de Lanaudière a été réalisé par une équipe du centre
                            Urbanisation Culture Société de l'Institut national de la recherche scientifique
                            pour le compte de Lanaudière économique, en collaboration avec Services Québec Lanaudière.</p>
                        <h3 className={classes.subtitle}>Équipe de l'INRS</h3>
                        <ul className={clsx("list-unstyled")}>
                            <li>Philippe Apparicio, professeur chercheur<br/>
                                Coordinateur du projet, conception de la cartographie interactive,
                                structuration des données spatiales.
                            </li>
                            <li className={clsx("pt-3")}>David Maignan, développeur<br/>
                                Architecture, programmation et déploiment du site.
                            </li>
                            <li className={clsx("pt-3")}>Jérémy Gelb<br/>
                                Support pour l’intégration des données spatiales.
                            </li>
                        </ul>
                        <h3 className={clsx(classes.subtitle, "mt-xl-4")}>Équipe de Services Québec Lanaudière</h3>
                        <ul className={clsx("list-unstyled")}>
                            <li>Corinne Desfossés, économiste régionale</li>
                            <li className={clsx("pt-3")}>Pierre Buisson, conseiller régional en information sur le marché du travail</li>
                            <li className={clsx("pt-3")}>Alexia Landreville, conseillère régionale des services aux entreprises</li>
                        </ul>
                        <h3 className={clsx(classes.subtitle, "mt-xl-4")}>Équipe de Lanaudière Économique</h3>
                        <ul className={clsx("list-unstyled")}>
                            <li>Jean-François Dupuis, directeur général</li>
                        </ul>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
};

export function Home(){
    return (
        <main role="main">
            <Jumbotron />
            <HomeDescription />
        </main>
    )
};

function GlossaryDescription(){
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="glossary">Glossaire</h2>
                    <p>Les activités économiques sont regroupées en cinq grands secteurs et 41 secteurs basés sur les
                        codes du Système de classification des industries de l’Amérique du Nord (SCIAN).</p>

                    <h2 className="h5 title mt-4 mb-4">Liste et définition des 41 secteurs d'activité (regroupement de secteurs SCIAN)</h2>
                    <table className="table table-bordered table-striped table-sm table-hover">
                        <thead>
                            <tr className="table-secondary">
                                <th>Numéro et nom du secteur</th>
                                <th>Code SCIAN</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1. Agriculture, pêche et chasse</td>
                                <td>111, 112, 114, 1151, 1152</td>
                            </tr>
                            <tr>
                                <td>2. Foresterie et exploitation forestière</td>
                                <td>113, 1153</td>
                            </tr>
                            <tr>
                                <td>3. Extraction minière</td>
                                <td>21</td>
                            </tr>
                            <tr>
                                <td>4. Services publics</td>
                                <td>22</td>
                            </tr>
                            <tr>
                                <td>5. Construction</td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <td>6. Aliments, boissons et tabac</td>
                                <td>311, 312</td>
                            </tr>
                            <tr>
                                <td>7. Produits textiles, vêtements et produits en cuir</td>
                                <td>313, 314, 315, 316</td>
                            </tr>
                            <tr>
                                <td>8. Papier</td>
                                <td>322</td>
                            </tr>
                            <tr>
                                <td>9. Impression et activités connexes de soutien</td>
                                <td>323</td>
                            </tr>
                            <tr>
                                <td>10. Pétrole, charbon et produits chimiques</td>
                                <td>324, 325</td>
                            </tr>
                            <tr>
                                <td>11. Produits en plastique et en caoutchouc</td>
                                <td>326</td>
                            </tr>
                            <tr>
                                <td>12. Produits en bois</td>
                                <td>321</td>
                            </tr>
                            <tr>
                                <td>13. Produits minéraux non métalliques</td>
                                <td>327</td>
                            </tr>
                            <tr>
                                <td>14. Première transformation des métaux</td>
                                <td>331</td>
                            </tr>
                            <tr>
                                <td>15. Produits métalliques</td>
                                <td>332</td>
                            </tr>
                            <tr>
                                <td>16. Machines</td>
                                <td>333</td>
                            </tr>
                            <tr>
                                <td>17. Produits informatiques, électroniques et électriques</td>
                                <td>334, 335</td>
                            </tr>
                            <tr>
                                <td>18. Produits aérospatiaux et leurs pièces</td>
                                <td>* 3364</td>
                            </tr>
                            <tr>
                                <td>19. Autres matériels de transport</td>
                                <td>336</td>
                            </tr>
                            <tr>
                                <td>20. Meubles et de produits connexes</td>
                                <td>337</td>
                            </tr>
                            <tr>
                                <td>21. Activités diverses de fabrication</td>
                                <td>339</td>
                            </tr>
                            <tr>
                                <td>22. Commerce de gros</td>
                                <td>41</td>
                            </tr>
                            <tr>
                                <td>23. Commerce de détail</td>
                                <td>44, 45</td>
                            </tr>
                            <tr>
                                <td>24. Transport et entreposage</td>
                                <td>48, 49</td>
                            </tr>
                            <tr>
                                <td>25. Finances</td>
                                <td>521, 522, 523</td>
                            </tr>
                            <tr>
                                <td>26. Sociétés d'assurance, fonds et autres instruments financiers</td>
                                <td>524, 526</td>
                            </tr>
                            <tr>
                                <td>27. Services immobiliers et de location et de location à bail</td>
                                <td>53</td>
                            </tr>
                            <tr>
                                <td>28. Services juridiques et de comptabilité</td>
                                <td>5411, 5412</td>
                            </tr>
                            <tr>
                                <td>29. Architecture, génie et services connexes et services spécialisés de design
                                </td>
                                <td>5413, 5414</td>
                            </tr>
                            <tr>
                                <td>30. Conception de systèmes informatiques et services connexes</td>
                                <td>5415</td>
                            </tr>
                            <tr>
                                <td>31. Services de conseils en gestion, de conseils scientifiques et techniques et
                                    de recherche
                                    et de développement scientifiques
                                </td>
                                <td>5416, 5417</td>
                            </tr>
                            <tr>
                                <td>32. Publicité, relations publiques et services connexes et autres services
                                    professionnels,
                                    scientifiques et techniques
                                </td>
                                <td>5418, 5419</td>
                            </tr>
                            <tr>
                                <td>33. Gestion de sociétés et d'entreprises et services administratifs, de soutien
                                    et autres
                                </td>
                                <td>55, 56</td>
                            </tr>
                            <tr>
                                <td>34. Services d'enseignement</td>
                                <td>61</td>
                            </tr>
                            <tr>
                                <td>35. Soins de santé</td>
                                <td>621, 622, 623</td>
                            </tr>
                            <tr>
                                <td>36. Assistance sociale</td>
                                <td>624</td>
                            </tr>
                            <tr>
                                <td>37. Industrie de l'information et industrie culturelle</td>
                                <td>51</td>
                            </tr>
                            <tr>
                                <td>38. Arts, spectacles et loisirs</td>
                                <td>71</td>
                            </tr>
                            <tr>
                                <td>39. Hébergement et restauration</td>
                                <td>72</td>
                            </tr>
                            <tr>
                                <td>40. Autres services (sauf les administrations publiques)</td>
                                <td>81</td>
                            </tr>
                            <tr>
                                <td>41. Administrations publiques</td>
                                    <td>91</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="font-italic small">* Pour une description détaillée des regroupements, on pourrait
                        consulter <a target="_blank"  rel="noopener noreferrer"
                                     href="http://imt.emploiquebec.gouv.qc.ca/mtg/inter/noncache/contenu/asp/mtg321_resultrechrsectactv_01.asp?lang=FRAN&Porte=3&cregncmp1=QC&PT4=53&sectprech=1&cregn=QC&PT1=8&secttypeprin=04&secttypesec=11&PT2=21&PT3=16">le site de l'IMT.</a></p>
                    <p className="font-italic small">* En raison d'effectifs d'emplois et de travailleurs très faibles
                        dans la région de Lanaudière,
                        le secteur 18 n'est pas représenté cartographiquement.</p>
                </div>
            </div>
        </div>
    )
};

export function Glossary(){
    return (
        <main role="main">
            <Jumbotron />
            <GlossaryDescription />
        </main>
    )
};
