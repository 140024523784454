import { createMuiTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import deepOrange from "@material-ui/core/colors/deepOrange";
import blueGrey from "@material-ui/core/colors/blueGrey";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import amber from "@material-ui/core/colors/amber";
import blue from "@material-ui/core/colors/blue";
import lightGreen from "@material-ui/core/colors/lightGreen";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import lime from "@material-ui/core/colors/lime";
import yellow from "@material-ui/core/colors/yellow";
import {purple} from "@material-ui/core/colors";

const hue = key => {
    return {"main": key[600], "dark": key[900], "light": key[200]}
}
export const getIsochroneColors = _ => {
    return [theme.palette.green,
            theme.palette.lightGreen,
            theme.palette.lime,
            theme.palette.orange,
            theme.palette.red,
    ]
}

export const theme = createMuiTheme({
    typography: {
        fontFamily: ['Roboto', 'sans', 'Arial'].join(','),
    },
    palette: {
        'gray': hue(grey),
        'yellow': hue(yellow),
        'teal': hue(teal),
        'green': hue(green),
        'orange': hue(orange),
        'deepOrange': hue(deepOrange),
        'blueGrey': hue(blueGrey),
        'red': hue(red),
        'amber': hue(amber),
        'blue': hue(blue),
        'lightGreen': hue(lightGreen),
        'lime': hue(lime),
        'purple': hue(purple),
        'primary': {
            light: '#8cbaed',
            main: '#4277b3',
            dark: '#174171',
        },
        background: {
            default: "white"
        }
    }
});