import {createSlice} from "@reduxjs/toolkit";
import {createSelector} from 'reselect';
import StorageService from "../../services/storage";
import {Address, PostCode} from "../../model/company";
const storage = new StorageService();

let postcode = storage.getPostcode();
let address = storage.getAddress();

export const addressSlice = createSlice({
    'name': 'address',
    'initialState': {
        type: 'postcode',
        value: {
            address: address,
            postcode: postcode,
            center: null,
        },
        offices: []
    },
    reducers: {
        setAddress: (state, action) => {
            if(action.payload !== null){
                storage.setAddress(action.payload);
                state.value.address = new Address(action.payload);
            }
        },
        setPostcode: (state, action) => {
            if(action.payload !== null){
                storage.setPostcode(action.payload);
                state.value.postcode = new PostCode(action.payload);
            }
        },
        deletePostcode: (state) => {
            storage.setPostcode(null);
            state.value.postcode = null;
        },
        deleteAddress:((state, action) => {
            storage.setAddress(null);
            state.value.address = null;
        }),
        setCenter:(((state, action) => {
            state.center = action.payload
        })),
        setType: ((state, action) => {
            state.type = action.payload
        })
    }
});
export const selectAddressType = state =>  state.address.type;
export const selectCenter = state => state.address.center;
export const getPlace = state => state.address.type === 'address' ? state.address.value.address : state.address.value.postcode;
export const selectAddress = state => state.address.value.address;
export const selectPostcode = state => state.address.value.postcode;
export const selectOffices = state => state.address.offices;
export const selectOffice = state =>  state.address.office;
export const {setAddress, deleteAddress, deletePostcode, setCenter, setType, setPostcode} = addressSlice.actions;
export const getLocalisation = createSelector(
    [getPlace],
    (place) => {

        return place === null;
    }
);
export const getCurrentPlace = createSelector(
    [selectAddressType, selectAddress, selectPostcode],
    (type, address, postcode) => {
        if(type === 'address'){
            return address
        } else {
            return postcode;
        }
    }
);

export default addressSlice.reducer;
