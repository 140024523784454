import React from 'react'

import {theme} from "../features/theme/theme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {makeStyles} from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import blue from "@material-ui/core/colors/blue";
import clsx from "clsx";

const styles = makeStyles({
    root:{
        backgroundColor: theme.palette.primary.dark,
    },
    h1: {
        fontFamily: 'Ubuntu',
        fontSize: "1.6em",
        fontWeight: "700",
        color: blue[400]
    },
    h2: {
        fontFamily: 'Ubuntu',
        fontSize: "1em",
        color: "#fff"
    },
    title: {
        fontSize: ".8rem"
    },
    p: {
        color: "#fff"
    },
    logo: {
        width: "155px",
            [theme.breakpoints.down('sm')]: {
                width: "130px"
            }
    },
    logo2: {
        width: "200px",
        [theme.breakpoints.down('sm')]: {
            width: "178px"
        }
    }
})

export function FooterContent(){
    const classes = styles();

    return (
            <div className={clsx("row pt-4 pb-4")}>
                <div className="col-md-4 col-12 mt-3">
                <p className={clsx(classes.title)}>Avec la participation financière de</p>
                        <a href="https://www.emploiquebec.gouv.qc.ca/" title="Emploi Québec" target="_blank" rel="noopener noreferrer" >
                            <img src="images/logo_emploi_quebec_sm.webp" title="Emploi Québec" alt="Emploi Québec"
                                 className={clsx(classes.logo, "mb-2")}/>
                        </a>
                </div>
                <div className="col-md-4 col-12 mt-3">
                    <p className={clsx(classes.title)}>En partenariat avec</p>
                    <a href="https://www.lanaudiere-economique.org/" title="Lanaudière Économique" target="_blank" rel="noopener noreferrer" >
                        <img src="images/logo_lanaudiere_economique.webp" title="Lanaudière Économique" alt="Lanaudière Économique"
                             className={clsx(classes.logo, "mb-2")}/>
                    </a>
                </div>
                <div className="col-md-4 col-12 mt-3">
                    <p className={clsx(classes.title)}>Réalisation</p>
                    <a href="http://laeq.ucs.inrs.ca/fr" title="LAEQ" target="_blank" rel="noopener noreferrer" >
                        <img src="images/logo_laeq.webp"
                             title="Laboratoire d'équité environnementale"
                             alt="LAEQ" className={classes.logo2}/>
                    </a>
                </div>
            </div>
    )
}

export function Footer(){
    const classes = styles();

    return(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div>
                <div className="bg-light" >
                    <div className="container-fluid">
                        <FooterContent />
                    </div>
                </div>

                <footer className={classes.root}>
                    <div className="container-fluid">
                        <div className={clsx("row pt-5 pb-5", classes.marginLeft)}>
                            <div className="col-12">
                                <h1 className={classes.h1}>GÉOLOCALISATEUR</h1>
                                <h2 className={classes.h2}>D’ENTREPRISES DE LANAUDIÈRE</h2>
                                <p className={clsx("mt-4 small", classes.p)}>© INRS. Tous droits réservés. Tous les documents de ce site sont protégés
                                    en vertu des lois sur le droit d’auteur et aucun
                                    d’eux ne peut être reproduit, distribué, transmis, affiché, publié ou même diffusé sans
                                    l’autorisation écrite préalable de l’INRS.
                                    Les utilisateurs peuvent les télécharger ou les imprimer pour leur usage personnel.</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </ThemeProvider>
    )
}
