import axios from 'axios';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSelector} from 'reselect';
import {getIsochroneColors} from "../../theme/theme";
import {getFavorite, getTimeId} from "../transport/transportSlice";

export const fetchIsochrone = createAsyncThunk("isochrone/", async(datas) => {
        const place = datas[0];
        const favorite = datas[1];
        const url = place.getURL(favorite);
        const response = await axios.get(url);

        return response.data;
});
export const isochroneSlice = createSlice({
    name: 'isochrone',
    initialState: {
        features: [],
        status: false,
        init: true,
        fitToBounds:  {
            toogle: true,
            time: undefined
        }
    },
    reducers: {
        setFitToBounds:((state, action) => {
            const toogle = ! state.fitToBounds.toogle;
            state.fitToBounds = {
                toogle: toogle,
                time: action.payload
            };
        })
    },
    extraReducers: {
        [fetchIsochrone.pending]: (state) => {
            state.status = true;
            state.features = [];
        },
        [fetchIsochrone.fulfilled]: (state, action) => {
            state.status = false;
            state.init = false;
            state.features = action.payload.sort((a,b) => b.properties.time - a.properties.time)
        },
        [fetchIsochrone.rejected]: (state) => {
            state.status = false;
            state.features = [];
        }
    }
});
export const getIsochrone = state => state.isochrone.features;
export const selectFit = state => state.isochrone.fitToBounds;
export const getFilterIsochrone = createSelector(
    [getIsochrone, getTimeId],
    (isochrone, time) => {
        let localTime = Math.min(isochrone.length, time);
        return isochrone.slice(-(++localTime));
});
export const getFitToBounds = createSelector(
    [selectFit, getFilterIsochrone],
    (fit, isochrone) => {
        const iso = isochrone.reduce((acc, curr) => {
            if(curr['properties']['time'] === fit.time){
                return curr;
            }
            return acc;
        }, isochrone[0]);

        return iso;
    }
);
export const getStatus = state => state.isochrone.status;
export const getIsochroneStyle = createSelector(
    [getFavorite],
    (favorite) => {
        if(favorite === null){
            return;
        }
        const cutoffsec = favorite.cutoffsec;
        const colors = getIsochroneColors();
        const result = colors.map(c => {
            return {
                color: c.main,
                stroke: true,
                weight: 3,
                fillOpacity: .05,
                fillRule: "evenodd"
            }
        });

        return function(feature){
            switch (feature.properties.time) {
                case cutoffsec[0]:
                    return result[0];
                case cutoffsec[1]:
                    return result[1];
                case cutoffsec[2]:
                    return result[2];
                case cutoffsec[3]:
                    return result[3];
                case cutoffsec[4]:
                    return result[4];
                case cutoffsec[5]:
                    return result[5];
                default:
                    return result[5];
            }
        }
    });
export const { setIsochrone, setFitToBounds } = isochroneSlice.actions;
export default isochroneSlice.reducer;