import React from 'react'
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {theme} from "../features/theme/theme";
import {ThemeProvider} from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {PlayCircleOutline} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import blue from "@material-ui/core/colors/blue";

const styles =  makeStyles({
    "root" : {
        "background-image": "url('images/lanaudiere.webp')",
        "background-opacity": "0.5",
        "background-repeat": "no-repeat",
        "background-position": "top right",
        "position": "relative",
        "color": "white",
        "background-color": "#174171",
        [theme.breakpoints.down('md')]: {
            color: "red",
            backgroundPosition:  "430px 0px",
            backgroundSize: "auto"
        },
        [theme.breakpoints.down('sm')]: {
            color: "red",
            backgroundPosition:  "280px -10px",
            backgroundSize: "auto"
        },
        [theme.breakpoints.down('xs')]: {
            backgroundPosition:  "20px 0px",
            backgroundSize: "750px"
        },
    },
    'h1': {
        color: blue[400],
        fontFamily: 'Ubuntu',
        fontSize: "3em",
        fontWeight: "400",
        textShadow: "1px 1px 1px white",
    },
    "p": {
        color: "white",
        fontFamily: 'Ubuntu',
        fontSize: "1.4em"
    }
});

export function Jumbotron(){
    const classes = styles();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <section className={clsx("jumbotron", "mt-5", classes.root)}>
                <div className="container">
                    <div className="col-12">
                        <h1 className={classes.h1}>Géolocalisateur <br/>d'entreprises de Lanaudière</h1>
                        <p className={clsx("lead", "mt-3", classes.p)}>Localiser les entreprises près de vous.</p>
                        <p>
                            <Link className="btn btn-primary mt-3" to="/geolocalisateur">
                                <PlayCircleOutline className="mt-0" size={"small"}/>
                                <Box >Démarrer une session</Box>
                            </Link>
                        </p>
                    </div>
                </div>
            </section>
        </ThemeProvider>
    );
}