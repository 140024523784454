import React, {Suspense} from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import {NavBar} from "./components/nav";
import {Glossary, Home} from "./components/home";
import {Footer} from "./components/footer";
import {CircularProgress} from "@material-ui/core";

const Favorites = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("./features/favorites/component")), 100)
        )
);

const Geolocalisateur = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("./components/geolocalisateur")), 100)
        )
);

export default function App() {
    return (
        <Router>
            <div>
                <NavBar />
                <Switch>
                    <Route path="/glossaire" component={Glossary} />
                    <Route path="/geolocalisateur">
                        <Suspense fallback={<div className={"mt-5 p-4"}><CircularProgress disableShrink /> Chargement en cours ...</div>}>
                            <Geolocalisateur />
                        </Suspense>
                    </Route>
                    <Route path="/mes-favoris" >
                        <Suspense fallback={<div className={"mt-5"}></div>}>
                            <Favorites />
                        </Suspense>
                    </Route>
                    <Route path="/" component={Home} />
                </Switch>
                <Footer />
                <CookieConsent disableStyles={false}
                               debug={false}
                               location="bottom"
                               buttonText="Accepter"
                               declineButtonText="Refuser"
                               cookieName="geoLanaudiereConsent"
                               enableDeclineButton
                               style={{ background: "#dedede", color: "#000", zIndex: 2000 }}
                               buttonStyle={{ color: "white", background: "black", fontSize: "12px" }}
                               declineButtonStyle={{ color: "#000", background: "white", fontSize: "12px" }}>
                    Ce site utilise des fichiers témoins (cookies) uniquement
                    pour se souvenir des choix que font les utilisateurs afin d'améliorer et de personnaliser leur expérience.</CookieConsent>
            </div>
        </Router>
    );
}
