import {getCookieConsentValue} from "react-cookie-consent";
import {Transport} from "../model/tranport";
import {PostCode, Address} from "../model/company";

function consent(){
    return getCookieConsentValue('geoLanaudiereConsent') == 'true';
}

export default class StorageService {
    getAddress(){
        const value = this.getItem('address');

        if(value){
            return new Address(value);
        }

        return value;
    }

    setAddress(address){
        this.save('address', address);
    }

    getPostcode(){
        const value = this.getItem('postcode');

        if(value){
            return new PostCode(value);
        }

        return value;
    }

    setPostcode(postcode){
        this.save('postcode', postcode)
    }

    getTransports(){
        return this.getItem('transports') || [];
    }

    setTransports(transports){
        this.save('transports', transports);
    }

    getFavoriteTransport(){
        let result = this.getItem('favoriteTransport');

        if(result){
            result = new Transport(result);
        }

        return result;
    }

    setFavoriteTransport(transport){
        this.save('favoriteTransport', transport)
    }

    getFavorites(){
        return this.getItem('favorites') || [];
    }

    setFavorites(values){
        this.save('favorites', values);
    }

    addFavorite(id){
        const values = this.getFavorites();
        if(values.includes(id) === false){
            values.push(id)
            this.save('favorites', values);
        }
    }

    getSector(){
        return this.getItem('sector') || [];
    }

    setSector(sectors){
        this.save('sector', sectors);
    }

    getProfession(){
        return this.getItem('profession') || [];
    }

    setProfession(professions){
        this.save('profession', professions);
    }

    save(item, values){
        if(consent() === false){
            return;
        }
        localStorage.setItem(item, JSON.stringify(values));
    }

    getItem(item){
        if(consent() === false){
            return null;
        }

        let result = localStorage.getItem(item);

        try{
            result =  JSON.parse(result);
        } catch (err){
            result = null;
        }

        return result;
    }

    getIsochrones() {
        return this.getItem('isochrone') || {}
    }

    saveIsochrone(payload){
        if(payload.key){
            const values = this.getIsochrones()
            let keys = Object.keys(values)

            if(keys.length > 30){
                const id = Math.floor(Math.random() * 20);
                delete values[keys[id]];
                values[payload.key] = payload.features;
            } else {
                values[payload.key] = payload.features;
            }

            this.save('isochrone', values)
        }
    }

    getIsochrone(key){
        const values = this.getIsochrones();

        if(values.hasOwnProperty(key)){
            return values[key]
        }

        return null;
    }

    setTransportId(id){
        this.save('transportId', id);
    }

    getTransportId(){
        const value = this.getItem('transportId');

        return value || 1;
    }

    getTimeId(){
        const value = this.getItem('timeId');

        if(value !== null){
            return value;
        }

        return 3;
    }

    setTimeId(id){
        this.save('timeId', id);
    }

    setIsochrone(key, value){
        this.save(key, value);
    }
}


