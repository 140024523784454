import React from "react";
import L from "leaflet";
import {point} from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import {blueIcon, redIcon} from "../features/map/helpers";

const SEARCH_HOST = process.env.REACT_APP_API_URL;

export class JobCenter {
    constructor(props) {
        this.name = props.name;
        this.address = props.address;
        this.city = props.city;
        this.phone = props.telephone1;
        this.fax = props.fax;
        this.postcode = props.postcode;
        this.latitude = props.latitude;
        this.longitude = props.longitude;
        this.point = this.getPoint();
    }

    getPoint(){
        return point([this.longitude, this.latitude]);
    }

    getFullAddress(){
        return `${this.address}<br />${this.postcode} - ${this.city}`;
    }

    getPosition(){
        return [this.latitude, this.longitude];
    }

    getDescription(){
        return `<b>${this.name}</b><br />${this.getFullAddress()}<br />Tél: ${this.phone}`;
    }

    getContact(){
        let result = '<p>';

        result += `Téléphone: ${this.phone}`;
        result += this.phone2 ? ` - ${this.phone2}`: "";

        return `${result}</p>`;
    }

    getMarker(){
        return  new L.marker([this.latitude, this.longitude], {icon: blueIcon })
            .bindPopup(this.getDescription(), {
                offset: [0,-35]
            });
    }
}
export class Company{
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.site = this.normalize(props.site);
        this.address_1 = props.address_1;
        this.address_2 = props.address_2;
        this.city = props.city;
        this.telephone = props.telephone
        this.postcode = props.postcode;
        this.latitude = props.latitude;
        this.longitude = props.longitude;
        this.size_id = props.size_id;
        this.linkName = this.getLinkedName();
        this.feature = null;
        this.sector = props.sector;
        this.point = this.getPoint();

        this.className = "image-icon";
    }

    normalize(site){
        if(site === "-" || site.length < 1){
            site = ""
        } else if(site.indexOf("http") === -1){
            site = `http://${site}`
        }

        return site
    }

    normalizeSite(){
        return this.normalize(this.site);
    }

    getPosition(){
        return [this.latitude, this.longitude]
    }

    getLinkedName(){
        return this.site.length > 0 ? <a href={this.site} target="_blank"  rel="noopener noreferrer" title={this.name}>{this.name}</a> : this.name;
    }

    getFullAddress(){
        let result = this.address_1

        if(this.address_2){
            result += ` ${this.address_2}`
        }

        return `${result}, ${this.city}`
    }

    getContact(){
        return this.telephone || ""
    }

    getDescription(){
        const nom = this.site.length > 0 ? `<a href="${this.site}" target="_blank" title="${this.name}">${this.name}</a>`: `${this.name}`

        return `<b>${nom}</b><br />${this.getFullAddress()}`;
    }

    getMarker(){
        return new L.marker(this.getPosition(), {
            icon: L.divIcon({
                html: "<img />",
                className: this.className,
                iconSize: [22,22]
            })
        }).bindPopup(this.getDescription(), {
            offset: [0,-5]
        });
    }

    getPoint(){
        return point([this.longitude, this.latitude])
    }

    setFeature(features){
        this.feature = null;

        try {
            let i = 0
            while(this.feature === null && i < features.length){
                if(booleanPointInPolygon(this.point, features[i].geometry)){
                    this.feature = parseInt(features[i].properties.time)
                }
                i++
            }
        } catch (e){

        }
    }

    toString(){
        return `<p>${this.address_1}</p><p><b>${this.city}</b> - ${this.postcode}</p>`
    }
}
export class PostCode {
    constructor(props) {
        this.id = props.id;
        this.latitude = props.latitude
        this.longitude = props.longitude
        this.value = props.value;
        this.jobcenter = new JobCenter(props.jobcenter);
    }

    getURL(favorite){
        return `${SEARCH_HOST}/isochrone/${favorite.name}/postcode/${this.id}`.toLowerCase();
    }

    getKey(transport){
        return `postcode:${this.id}:${transport.name}`;
    }

    getDescription(){
        return `<b>Localisation</b><br />${this.value}`;
    }

    getMarker(){
        return L.marker([this.latitude, this.longitude], {icon: redIcon}).bindPopup(this.getDescription(), {
            offset: [0,-30]
        });
    }

    getJobCenterPosition(){
        return [this.jobcenter.latitude, this.jobcenter.longitude];
    }

    toString(){
        return `${this.value}`
    }
}
export class Address{
    constructor(props) {
        this.id = props.id;
        this.latitude = props.latitude;
        this.longitude = props.longitude;
        this.short_address = props.short_address;
        this.city = props.city;
        this.jobcenter = new JobCenter(props.jobcenter);
    }

    getURL(favorite){
        return `${SEARCH_HOST}/isochrone/${favorite.name}/address/${this.id}`.toLowerCase();
    }

    getKey(transport){
        return `address:${this.id}:${transport.name}`;
    }

    getFullAddress(){
        return this.short_address;
    }

    getDescription(){
        return `<b>Localisation</b><br />${this.short_address}<br />${this.city}</br>`;
    }

    getMarker(){
        return L.marker([this.latitude, this.longitude], {icon: redIcon}).bindPopup(this.getDescription(), {
            offset: [0,-30]
        });
    }

    getJobCenterPosition(){
        return [this.jobcenter.latitude, this.jobcenter.longitude];
    }

    toString(){
        return `${this.short_address}, ${this.city}`
    }
}