import React from 'react';
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom';

import App from './App';
import store from "./store";
import * as serviceWorker from './serviceWorker';
import {theme} from "./features/theme/theme";
import {ThemeProvider} from "@material-ui/styles";
import {CssBaseline} from "@material-ui/core";

ReactDOM.render(
  <Provider store={store}>
      <ThemeProvider theme={theme}>
          <CssBaseline />
        <App />
      </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
