import { createStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from 'redux-thunk'
import {composeWithDevTools} from "redux-devtools-extension";
import {combineReducers} from "redux";

import addressReducer from "./features/address/addressSlice";
import transportReducer from "./features/map/transport/transportSlice";
import isochroneReducer from "./features/map/isochrone/isochroneSlice";
import jobReducer from "./features/job/jobSlice";

const middleware = [thunk];

const rootReducer = combineReducers({
    transport: transportReducer,
    isochrone: isochroneReducer,
    address: addressReducer,
    job: jobReducer,
});

export default createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);