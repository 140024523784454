export class Transport{
    constructor(props){
        this.id = props.id;
        this.name = props.name;
        this.gmap = props.gmap;
        this.type = props.type;
        this.path = props.path;
        if(Array.isArray(props.cutoffsec)){
            this.cutoffsec = props.cutoffsec;
        }else {
            this.cutoffsec = props.cutoffsec.split(",").map(v => parseInt(v) * 60);
        }

        if(props.timeId){
            this.timeId = props.timeId;
        } else {
            this.timeId = 3;
        }
    }

    availableTimes(){
        return this.cutoffsec.slice(0, this.timeId + 1);
    }

    favTime(){
        return this.cutoffsec[this.timeId];
    }

    cutoffsecs(){
        return this.cutoffsec.map(c => c * 60);
    }
}