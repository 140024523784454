import {createSelector} from "reselect";
import axios from 'axios';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import StorageService from "../../services/storage";
import {Company, JobCenter} from "../../model/company";
import {getFilterIsochrone, getIsochrone} from "../map/isochrone/isochroneSlice";
import {getAvailableTimes, getDirectionUrl} from "../map/transport/transportSlice";
import flatten from "@turf/flatten";

const SEARCH_HOST = process.env.REACT_APP_API_URL;
const storage = new StorageService();

export const fetchCompanyProfession = createAsyncThunk("company/profession", async(professions) => {
    let ids = ''
    if(professions.length > 0){
        ids = professions.map(s => s.id)
        ids = `?ids=${ids.join(",")}`
    }

    const url = `${SEARCH_HOST}/company/professions${ids}`
    const response = await axios.get(url);
    return response.data;
});
export const fetchCompanySectors = createAsyncThunk("company/profession", async(sectors) => {
    let ids = ''
    if(sectors.length > 0){
        ids = sectors.map(s => s.id)
        ids = `?ids=${ids.join(",")}`
    }

    const url = `${SEARCH_HOST}/company/sectors${ids}`
    const response = await axios.get(url);
    return response.data;
});
export const fetchProfession = createAsyncThunk("profession", async() => {
    const url = `${SEARCH_HOST}/profession/`
    const response = await axios.get(url);
    return response.data;
})
export const fetchSectors = createAsyncThunk("sectors", async() => {
    const url = `${SEARCH_HOST}/sector/`
    const response = await axios.get(url);
    return response.data;
})
export const fetchJobcenters = createAsyncThunk("jobcenters", async() => {
    const url = `${SEARCH_HOST}/jobcenter/`
    const response = await axios.get(url);
    return response.data;
})
export const jobSlice = createSlice({
    name: 'job',
    initialState: {
        type: "profession",
        selection: {
            'profession': storage.getProfession(),
            'sector': storage.getSector(),
            'companies': storage.getFavorites()
        },
        companies: [],
        professions: [],
        sectors: [],
        jobcenters: []
    },
    reducers: {
        setType: ((state, action) => {
            state.type = action.payload
        }),
        setProfessions: ((state, action) => {
            storage.setProfession(action.payload)
            state.selection.profession = action.payload
        }),
        setSectors:(((state, action) => {
            storage.setSector(action.payload)
            state.selection.sector = action.payload
        })),
        addToFavorites:(((state, action) => {
            storage.addFavorite(action.payload)
            state.selection.companies = [...new Set([action.payload,...state.selection.companies])]
        })),
        removeFromFavorites: ((((state, action) => {
            const list = state.selection.companies.filter(id => id !== action.payload)
            state.selection.companies = list

            storage.save('favorites', list)
        })))
    },
    extraReducers: {
        [fetchCompanyProfession.pending]: (state) => {
            state.status = true;
            state.companies = [];
        },
        [fetchCompanyProfession.fulfilled]: (state, action) => {
            state.status = false;
            state.companies = action.payload;
        },
        [fetchCompanyProfession.rejected]: (state) => {
            state.status = false;
            state.companies = [];
        },
        [fetchProfession.pending]: (state) => {
            state.status = true;
            state.professions = [];
        },
        [fetchProfession.fulfilled]: (state, action) => {
            state.status = false;
            state.professions = action.payload;
        },
        [fetchProfession.rejected]: (state) => {
            state.status = false;
            state.professions = [];
        },
        [fetchSectors.pending]: (state) => {
            state.status = true;
            state.sectors = [];
        },
        [fetchSectors.fulfilled]: (state, action) => {
            state.status = false;
            state.sectors = action.payload;
        },
        [fetchSectors.rejected]: (state) => {
            state.status = false;
            state.sectors = [];
        },
        [fetchCompanyProfession.pending]: (state) => {
            state.status = true;
            state.companies = [];
        },
        [fetchCompanyProfession.fulfilled]: (state, action) => {
            state.status = false;
            state.companies = action.payload.map(c => new Company(c));
        },
        [fetchCompanyProfession.rejected]: (state) => {
            state.status = false;
            state.companies = [];
        },
        [fetchCompanySectors.pending]: (state) => {
            state.status = true;
            state.companies = [];
        },
        [fetchCompanySectors.fulfilled]: (state, action) => {
            state.status = false;
            state.companies = action.payload.map(c => new Company(c));
        },
        [fetchCompanySectors.rejected]: (state) => {
            state.status = false;
            state.companies = [];
        },
        [fetchJobcenters.pending]: (state) => {
            state.status = true;
            state.companies = [];
        },
        [fetchJobcenters.fulfilled]: (state, action) => {
            state.status = false;
            state.jobcenters = action.payload.map(c => new JobCenter(c));
        },
        [fetchJobcenters.rejected]: (state) => {
            state.status = false;
            state.companies = [];
        }
    }
});
export const selectProfessions = state => state.job.professions;
export const selectJobCenter = state => state.job.jobcenters;
export const selectSectors = state => state.job.sectors;
export const selectedProfessions = state => state.job.selection.profession;
export const selectedSectors = state => state.job.selection.sector;
export const getType = state => state.job.type;
export const selectTotalCompanies = state => state.job.companies.length;
export const stateCompanies = state => state.job.companies;
export const selectFavorites = state => state.job.selection.companies;
export const getCompanies = createSelector(
    [stateCompanies, getDirectionUrl, selectedSectors],
    (companies, directionUrl, sectors) => {
        const ids = sectors.map(s => s.id);
        companies.forEach(c => {
            c.directionLink = directionUrl(c);

            const index = ids.indexOf(c.sector.id);
            if(index === -1){
                c.className = "image-icon";
            }else {
                c.className = `image-icon-sector-${index}`;
            }
        });
        return companies;
    }
);
export const getJobCenter = createSelector(
    [selectJobCenter],
    (jobcenter) => {
        return  jobcenter;
    }
);
export const setFeaturedCompanies = createSelector(
    [getCompanies, getIsochrone],
    (companies, isochrone) => {
        const stop = companies.length === 0 || isochrone.length === 0;
        if(stop){
            return []
        } else {
            let features = isochrone.map(i => flatten(i)).reverse();
            let reduction = features.reduce((prev, curr) => {
                return prev.concat(curr.features)
            },[]);

            companies.forEach(c => c.setFeature(reduction));
        }

        return companies
    }
);
export const getFilterCompanies = createSelector(
    [setFeaturedCompanies, getFilterIsochrone],
    (companies, isochrone) => {
        const stop = companies.length === 0 || isochrone.length === 0;
        if(stop){
            return [];
        }

        const times = isochrone.map(i => i.properties.time);
        return companies.filter(c => times.includes(c.feature));
    }
);

export const getSectorLegend = createSelector(
    [selectedSectors, getType],
    (sectors, type) => {
        if(type !== "profession"){
            return sectors;
        }

        return [];
    }
)

export const getCompanyStyle = createSelector(
    [getSectorLegend],
    (sectors) => {
        return function(sectorId){
            if(sectors.length === 0){
                return "image-icon"
            } else {
                const ids = sectors.map(s => s.id);
                const index = ids.indexOf(sectorId);

                if(index >= 0){
                    return `image-icon-sector-${index}`;
                }else {
                    return "image-icon";
                }
            }
        }
    }
);
export const getChartData = createSelector(
    [getFilterCompanies, getAvailableTimes],
    (companies, times) => {
        const stop = companies.length === 0 || times.length === 0;
        if(stop){
            return [];
        }

        const count = companies.map(c => c.feature).reduce((prev, curr) => {
            prev[curr] = ++prev[curr] || 1
            return prev
        }, {});
        const result = [];

        Object.keys(count).forEach(k => {
            const tmp = {}
            tmp['id'] = k;
            tmp['total'] = count[k]
            result.push(tmp);
        })

        return result;
    }
);
export const {setType, setProfessions, setSectors,
    addToFavorites, removeFromFavorites,
    setFeatures
} = jobSlice.actions;
export default jobSlice.reducer;